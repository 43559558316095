import { useState, useEffect } from "react";
import { uploadFile } from "../../API/Api"
import { toast } from "react-toastify";
import { jwtDecode } from "jwt-decode";
import { v4 as uuidv4 } from 'uuid';
import { DateTime } from "luxon";
import { useForm } from 'react-hook-form';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';


export function RightContainer({ children, className }) {
  return (
    <div className='flex-1 ml-80 px-9 pt-4 max-w-screen-2xl h-screen'> 
    {/* <div className='flex-1 ml-80 px-9 pt-4 overflow-x-auto max-w-screen-2xl'> */}
        {children}
        {/* h-screen was what made it flexible omg lol. */}
    </div>
  );
}

export function Badge({ children, className }) {
  return (
    <div className={`white rounded-full px-2 py-1 text-xs font-semibold ${className}`}>
      {children}
    </div>
  );
}

export function Card({ children, className }) {
  return (
    <div className={`bg-white rounded-sm shadow-md p-4 mb-4 mx-2 ${className}`}>
      {children}
    </div>
  );
}

export function Row({ children, className }) {
  return (
    <div className={`flex flex-row justify-between ${className}`}>
      {children}
    </div>
  );
}

export function SubmitButton({ children, className, onClick,  }) {
  const [submitted, setSubmitted] = useState(false)
  const handleSubmit = () => {
    setSubmitted(true)

    if (onClick && typeof onClick === 'function') {  
      onClick(); //eg, updateKyc(...)
    }
  }

  return (
    <button onClick={handleSubmit} disabled={submitted} className={`bg-green-500 text-white font-semibold py-2 px-4 rounded-md ${submitted ? 'cursor-not-allowed opacity-50' : 'hover:bg-green-600'} ${className}`}>
      {children}
    </button>
  )
}


// ------------------- forms utils ----------------------


export function UploadFileInput({ watch, register, setValue, formDisplayName, azureFileName, formName, readOnly=false, customName, className}) {
  const token = localStorage.getItem('accessToken')
  const decodedToken =  jwtDecode(token)
  const watchedValue = watch(formName); // this is actual name of file  
  const [fileName, setFileName] = useState(azureFileName)

  const handleFileUpload = async (e, fileType) => {
    // const fileInputFieldName = e.target.name;

    const file = e.target.files[0];
    if (!file) { return }
    if (!watchedValue) { setValue(formName, ' '); }
 

    try {
      const data = new FormData();
      data.append("file", file);
      const fileNewName = `${fileType}_${file.name}`; 

      const res = await uploadFile(data, fileNewName, token); 
      console.log('fsa')
      setFileName(fileNewName)
      setValue(formName, res.data.url);
    } catch (e) {
      setFileName('')
      setValue(formName, '');
      toast.error("Invalid file type!")
    }
  }

  const handleFileRemove = async (fileName) => {
    setValue(formName , '')
  }

  return (
    <div className={`${className}`}>
      <p className="text-sm font-medium text-gray-700 mb-2"> {formDisplayName} </p>
      {watchedValue && watchedValue[0] && typeof watchedValue === 'string' && (
        <p className=' mb-2'>
          <a href={`${process.env.REACT_APP_BACKEND_URL}/get-upload?fileName=${azureFileName}&accessToken=${token}`} target='_blank'  rel="noopener noreferrer" className='text-blue-600 mb-2'> {watchedValue?.split('/')?.slice(-1)[0] || ' '} </a> 
          <span className='cursor-pointer' onClick={() => handleFileRemove(formName)}> ❌ </span> 
        </p>
      )}
      <label className={`bg-gray-200 px-3 py-1 mt-5 rounded ${!readOnly && 'cursor-pointer'}`}>
        {customName ? customName : 'Browse'}
        <input {...register(formName, { onChange: (e) => { handleFileUpload(e, formName) } })} disabled={readOnly} type="file" name={formName} className="hidden"/>
      </label>
    </div>
  );
}



export function CommentSection({parentFormId, formName, disabled, pastComments=[], submitFormComment, readOnly=false }) {
  const token = localStorage.getItem('accessToken')
  const decodedToken =  jwtDecode(token)
  // zod check if comment string is blank, deny it otherwise

  const { register, reset, handleSubmit, setValue, watch, formState: {errors, isSubmitting } } = useForm({
    defaultValues: { 
      comment: '',
      commentId: '' || uuidv4(),
      commentorName: decodedToken.name,
      createdAt: '' || DateTime.now().toFormat('dd-MM-yyyy HH:mm'),
      parentFormId :  parentFormId
    }
    //  append to comments: [], must follow above convention
  });

  // this comment Component might be the one causing issues. 

  const onSubmit = async (commentData) => {
    if (commentData.comment.trim() === '') { return }

  // line 161 re-enable the submit on Click
    submitFormComment(commentData)
    //somehow even when submitformcomment function is empty, the response label gets reset as well
    
    setValue('comment', []) // RHF, reset() works on ALL fields, hence to reset only 1 field, setValue instead 
  }



  return (
    <div className="font-semibold mb-1 w-full"> 
      Comments: 
      {pastComments.map( (comment) => (
      // {allComments.map( (comment) => (
      // {value.comments.map( (comment) => (
        <div className='my-1 p-1.5 border-b'>
          <p className='text-xs underline font-normal flex justify-between'>
              <span> {comment?.commentorName}: </span> {comment?.createdAt}
          </p>
          <p className='w-full text-sm break-words'>
            {comment?.comment}
          </p>
        </div>
      ))} 
      <div className="flex">
        <input {...register('comment')} placeholder="Write comment"  disabled={disabled} className="w-11/12 p-1 font-normal border-b-2 border-b-gray-300 bg-gray-50 focus:outline-none focus:border-green-500"/>
        {/* <input placeholder="Write comment" name={inputName} value={value?.newComment} onChange={(e) => handleInputChange(e, 'newComment')} className="w-11/12 p-1 font-normal border-b-2 border-b-gray-300 bg-gray-50 focus:outline-none focus:border-green-500"/> */}
        {/* <span className='cursor-pointer'> */}
        <span onClick={handleSubmit(onSubmit)} className={`${!disabled && 'cursor-pointer'}`}>
        {/* <span onClick={(e) => handleSubmitComment(e, 'newComment', inputName)} className='cursor-pointer'> */}
          <svg width="32px" height="32px" viewBox="-6.24 -6.24 36.48 36.48" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#ffffff">
          <g id="SVGRepo_bgCarrier" strokeWidth="0" transform="translate(0,0), scale(1)">
          <rect x="-6.24" y="-6.24" width="36.48" height="36.48" rx="18.24" fill="#25e44b" strokeWidth="0"/> </g>
          <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" stroke="#CCCCCC" strokeWidth="0.288"/>
          <g id="SVGRepo_iconCarrier"> <path d="M3 7.2C3 6.07989 3 5.51984 3.21799 5.09202C3.40973 4.71569 3.71569 4.40973 4.09202 4.21799C4.51984 4 5.0799 4 6.2 4H17.8C18.9201 4 19.4802 4 19.908 4.21799C20.2843 4.40973 20.5903 4.71569 20.782 5.09202C21 5.51984 21 6.0799 21 7.2V20L17.6757 18.3378C17.4237 18.2118 17.2977 18.1488 17.1656 18.1044C17.0484 18.065 16.9277 18.0365 16.8052 18.0193C16.6672 18 16.5263 18 16.2446 18H6.2C5.07989 18 4.51984 18 4.09202 17.782C3.71569 17.5903 3.40973 17.2843 3.21799 16.908C3 16.4802 3 15.9201 3 14.8V7.2Z" stroke="#f7f7f7" strokeWidth="2.4" strokeLinecap="round" strokeLinejoin="round"/> </g>
          </svg>
        </span>
      </div>
    </div>  
  )
}
  

export function MessageIcon() {
  <div className="scale-50"> 
    <svg width="64px" height="64px" viewBox="-6.24 -6.24 36.48 36.48" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#ffffff">
    <g id="SVGRepo_bgCarrier" strokeWidth="0" transform="translate(0,0), scale(1)">
    <rect x="-6.24" y="-6.24" width="36.48" height="36.48" rx="18.24" fill="#25e44b" strokeWidth="0"/> </g>
    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" stroke="#CCCCCC" strokeWidth="0.288"/>
    <g id="SVGRepo_iconCarrier"> <path d="M3 7.2C3 6.07989 3 5.51984 3.21799 5.09202C3.40973 4.71569 3.71569 4.40973 4.09202 4.21799C4.51984 4 5.0799 4 6.2 4H17.8C18.9201 4 19.4802 4 19.908 4.21799C20.2843 4.40973 20.5903 4.71569 20.782 5.09202C21 5.51984 21 6.0799 21 7.2V20L17.6757 18.3378C17.4237 18.2118 17.2977 18.1488 17.1656 18.1044C17.0484 18.065 16.9277 18.0365 16.8052 18.0193C16.6672 18 16.5263 18 16.2446 18H6.2C5.07989 18 4.51984 18 4.09202 17.782C3.71569 17.5903 3.40973 17.2843 3.21799 16.908C3 16.4802 3 15.9201 3 14.8V7.2Z" stroke="#f7f7f7" strokeWidth="2.4" strokeLinecap="round" strokeLinejoin="round"/> </g>
    </svg>
  </div>
}
  



//  multi select where options are array of objects
{/* // <CheckboxesTags field={field} allOptions={allUsers} displayProperty={'email'}/> */}


// export function CheckboxesTags({field, allOptions, displayProperty}) {
//   const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
//   const checkedIcon = <CheckBoxIcon fontSize="small" />;
//   const top100Films = [   { title: 'The Shawshank Redemption', year: 1994 },   { title: 'The Godfather', year: 1972 },   { title: 'The Godfather: Part II', year: 1974 },   { title: 'The Dark Knight', year: 2008 },   { title: '12 Angry Men', year: 1957 },   { title: "Schindler's List", year: 1993 },   { title: 'Pulp Fiction', year: 1994 }]
  
//   return (
//     <Autocomplete
//       {...field}
//       multiple
//       size="small"
//       id="checkboxes-tags-demo"
//       options={allOptions}
//       disableCloseOnSelect
//       getOptionLabel={(option) => option[displayProperty]}
//       isOptionEqualToValue={(option, value) => option[displayProperty] === value[displayProperty]}
//       renderOption={(props, option, { selected }) => {
//         const { key, ...optionProps } = props;
//         return (
//           <li key={key} {...optionProps}>
//             <Checkbox
//               icon={icon}
//               checkedIcon={checkedIcon}
//               style={{ marginRight: 8 }}
//               checked={selected}
//             />
//             {option[displayProperty]}
//           </li>
//         );
//       }}
//       style={{ width: '100%' }}
//       renderInput={(params) => (
//         <TextField {...params} label="Select Emails" placeholder="" />
//       )}
//       onChange={(_, data) => field.onChange(data)} // Ensure form state is updated
//     />
//   );
// }
 


//  multi select where options are array of strings
export function CheckboxesTags({field, allOptions}) {
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  
  return (
    <Autocomplete
      {...field}
      multiple
      size="small"
      id="checkboxes-tags-demo"
      options={allOptions}  // Now an array of emails
      disableCloseOnSelect
      getOptionLabel={(option) => option}  // Return the email directly
      isOptionEqualToValue={(option, value) => option === value}  // Compare by email (string comparison)
      renderOption={(props, option, { selected }) => {
        const { key, ...optionProps } = props;
        return (
          <li key={key} {...optionProps}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option}  {/* Display email directly */}
          </li>
        );
      }}
      style={{ width: '100%' }}
      renderInput={(params) => (
        <TextField {...params} label="Select Emails" placeholder="" />
      )}
      onChange={(_, data) => field.onChange(data)}  // Pass the selected emails to the form state
    />
  );
}