import React, {useEffect, useState} from 'react';
import { useLocation } from 'react-router-dom';
import { getkyc  } from "../../../API/Api"
import _IndividualDetails from './_IndividualDetails'
import _EntityDetails from './_EntityDetails'
import _RiskAssessment from './_RiskAssessment'
import FormStatusEnum from '../../../data/FormStatusEnum';
import PageNotFound from '../../../PageNotFound'
import OnGoingMonitoring from './OnGoingMonitoring';
import FormSteps from './FormSteps'
import { ToastContainer } from 'react-toastify';
import { jwtDecode } from 'jwt-decode';

const __KycAmlForm = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = localStorage.getItem('accessToken') || queryParams.get('formToken')
  const decodedToken =  jwtDecode(token)
  const currentUrl = location.pathname
  const id = queryParams.get('id'); 
  const currentPage = queryParams.get('page'); 
  const companyId = queryParams.get('companyId') || decodedToken.companyId; 

  const [clientData, setclientData] = useState({name: '', type: '', formStatus: '', maxPage:''})
  const [formCurrentPage, setformCurrentPage] = useState(currentPage)

  useEffect( () => {
    const fetchData = async () => { 
      const kycData = await getkyc(id, token) 
      setclientData(kycData.data)
    }
    fetchData()
      }, 
  [formCurrentPage]);

    let readOnlyOne = false;
    let readOnlyTwo = false; 
    if (clientData.maxPage > 1 || decodedToken.role == 'viewer') { readOnlyOne=true }
    if (clientData.maxPage > 2 || decodedToken.role == 'viewer') { readOnlyTwo=true }
 
    // check if record exists 
    if (!clientData?.formStatus) { return <PageNotFound /> } 
     
    // if encoded formToken userId matches the user form, then show.
    if (currentUrl === '/form/kyc-aml' && (decodedToken?.userId == id || decodedToken?.email )) {
      return (
      <div className='flex flex-col items-center justify-center min-h-screen w-full bg-slate-300 absolute top-0 left-0 right-0 z-50'>
        <ToastContainer pauseOnHover={false} pauseOnFocusLoss={false} limit={3} autoClose={2750}/>
        {clientData.type === 'individual'  && <_IndividualDetails currentUrl={currentUrl} token={token} individualName={clientData.name} userId={id} companyId={companyId} readOnly={readOnlyOne} maxPage={clientData.maxPage}/>}
        {clientData.type === 'entity' && <_EntityDetails currentUrl={currentUrl} token={token} companyName={clientData.name} userId={id} companyId={companyId} readOnly={readOnlyOne} maxPage={clientData.maxPage}/>} {/* //  clientData.formStatus === FormStatusEnum.PENDING_CLIENT_DETAILS_1) &&  */}
      </div>
  )} else if (currentUrl === '/form/kyc-aml' && !decodedToken?.email) { // FormToken would not have 'email property'
    return <PageNotFound /> 
  }


  // Internal Link Form
  if (currentPage==1 && clientData.maxPage >= 1) {
    return <>
      <FormSteps/>
      {clientData.type === 'individual'  && <_IndividualDetails token={token}  currentUrl={currentUrl} individualName={clientData.name} userId={id} readOnly={readOnlyOne}/>}
      {clientData.type === 'entity' && <_EntityDetails token={token} currentUrl={currentUrl} companyName={clientData.name} userId={id} readOnly={readOnlyOne}/>} {/* //  clientData.formStatus === FormStatusEnum.PENDING_CLIENT_DETAILS_1) &&  */}
      </>
  } else if (currentPage==2 && clientData.maxPage >= 2) {
    return <>
      <FormSteps/>
      <_RiskAssessment token={token} currentUrl={currentUrl} userId={id} userType={clientData.type} readOnly={readOnlyTwo} /> {/* PENDING_RISK_ASSESSMENT_2,  PENDING_ADMIN_REVIEW_3, REJECTED_4 */}
      </>
  } else if (currentPage==3 && clientData.maxPage >= 3) {
    return <>
      <FormSteps/>
      <OnGoingMonitoring token={token} currentUrl={currentUrl} userId={id} userType={clientData.type} /> {/* ACCEPTED_4 */}
      </>
  }  else {
    return <>
      <FormSteps />
      <div>
        Please complete previous form steps!
      </div>
    </>
  }
};

export default __KycAmlForm;
