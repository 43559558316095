// import SignatureCanvas from 'react-signature-canvas';
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { getkyc, updatekyc, uploadFile, sendEmailNotification, frontEndBaseURL, createNotification , generateFormToken } from "../../../API/Api"
import { toast } from 'react-toastify';
import FormStatusEnum from '../../../data/FormStatusEnum';
import CountryList from './CountryList';
import ConnectedOrBeneficialParty from './ConnectedOrBeneficialParty'; 
import Tooltip from '@mui/material/Tooltip'; 
import { jwtDecode } from "jwt-decode";
import { DateTime } from 'luxon';
import Modal from "../../shared/Modal";


const _EntityDetails = ({ companyName, companyId, userId, readOnly, currentUrl, maxPage, token }) => {
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [mandatoryFieldError, setMandatoryFieldError] = useState({
    name: '', uenNumber: '', incorporationCountry: '', incorporationDate: '', registeredAddress : '', contactNumber: '', email: '', taxResidenceCountry: 'SG', natureOfBusiness : '',
  });  
  const [mandatoryConnectedFieldError, setMandatoryConnectedFieldError] = useState([]) // name: '', address: '', gender: '', identificationNumber: ''



  const [formData, setFormData] = useState({
    _id : userId, name: '', uenNumber: '', incorporationCountry: '', incorporationDate: '', registeredAddress : '', contactNumber: '', email: '', formStatus: '',
    connectedParty: [], 
    // [{
    //   relationship: '', name: '', gender: '', address: '', identificationNumber: '', identificationDocumentURL: '', identificationExpiryDate: '', 
    //   birthDate: '', address: '', email: '', residenceStatus: '', nationality: '',
    // 
    //   pepPerson: ' - No', pepPersonCountryOrOrganisation: '', pepPersonPublicFunctionNature: '', pepPersonRoleFrom: '', pepPersonRoleTo: '',  identificationDocumentLink: '', identificationExpiryDate: '',  
    //   pepAssociate: ' - No', pepAssociateRelationship: '',  pepAssociateName: '', pepAssociateCountryOrOrganisation: '', pepAssociatePublicFunctionNature: '',pepAssociateRoleFrom: '', pepAssociateRoleTo: '',
    //   pepFamily: ' - No', pepFamilyRelationship: '', pepFamilyName: '', pepFamilyCountryOrOrganisation: '', pepFamilyPublicFunctionNature: '', pepFamilyRoleFrom: '', pepFamilyRoleTo: '',
    // }],
    singaporeGovernmentEntity: '', listedOnStockExchange:'', financialInstitution:'', incumbencyCertificateUrl:'', companyConstitution:'', organisationChart:'',
    natureOfBusiness : '', sourceOfWealth : '', taxResidenceCountry: 'SG', declarationName: '', declarationDate: '', signatureUrl: '', accountManager: ''
  });  

  const decodedToken =  jwtDecode(token)
  // let signatureRef = useRef(); 
  const navigate = useNavigate()

  useEffect(() => {
    const fetchData = async () => {  
        const userData = await getkyc(userId, token)  
 
        setFormData({ 
          ...userData.data?.details,
          taxResidenceCountry: userData.data?.details?.taxResidenceCountry || "SG", 
          incorporationCountry: userData.data?.details?.incorporationCountry || "SG", 
          _id : userId,
          name: userData.data.name,
          email : userData.data.email,
          formStatus : userData.data.formStatus,
          connectedParty: userData.data.details?.connectedParty || [], 
        }) 

        userData.data?.connectedParty?.forEach( party => {
          mandatoryConnectedFieldError.append({name: '', address: '', gender: '', identificationNumber: ''})
        })
        setMandatoryConnectedFieldError(mandatoryConnectedFieldError)
        
      }
      fetchData()
  }, []);  
 

  const handleFileUpload = async (e, fileType) => {
    const { name } = e.target;
    const file = e.target.files[0];
    if (!file) { return }

    try {
      const data = new FormData();
      data.append("file", file);
      const filename = `${fileType}_${file.name}`;
 
      const res = await uploadFile(data, filename, token); 
      setFormData(prevState => ({
        ...prevState,
        [name] : res.data.url
        // identificationDocumentLink :  res.data.url // name refer to 'input name' property.
    }))

    } catch (e) {
      toast.error("Invalid file type!")
    }
  }
 

  const handleFileRemove = async (fileName) => {
    if (readOnly) { return } 
    
    setFormData(prevState => ({
      ...prevState,
      [fileName] : ''
    }))
  } 


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const newData = {
      ...formData,
      [name] : value // name refer to 'input name' property.
    }
    setFormData(newData) 

    
    const requiredFields = ["name", "uenNumber", "incorporationCountry", "incorporationDate", "registeredAddress", "contactNumber", "email", "taxResidenceCountry", "natureOfBusiness"];
    if (!requiredFields.includes(name)) { return }

    let newMandatoryFieldError = { ...mandatoryFieldError };
    if (value.trim() === "") { newMandatoryFieldError[name] = true }
    else { newMandatoryFieldError[name] = false }

    setMandatoryFieldError(newMandatoryFieldError);
  }
  

  const handleSaveForm = async (e) => { 
    // let signatureData = await signatureRef.current.toDataURL(); // Use the signature data (base64 image) as needed 
    const newFormData = {
      ...formData,  
      maxPage : 1
    } 

    // const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    // setIsValidEmail(emailPattern.test(formData.email));  // Check if the entered email matches the email pattern
    // for (const key in formData) {
    //       if (formData[key].trim() === '') {
    //           toast.info("Please fill in all fields")
    //           return 
    //       }
    // }

    setFormData(newFormData)
    toast.info("Form Saved!")

    await updatekyc(newFormData, token)
    if (currentUrl !== '/form/kyc-aml') {
      navigate('/kyc-aml/customer-list')
    }  
  }   

  const handleSendEmail = async () => { 
    const formTokenData = {
      name: formData.name,
      userId : userId,
      companyId : jwtDecode(token).companyId,
    }

    const formToken = (await generateFormToken(formTokenData, token)).data.accessToken  
    const linkToForm = "https://" + frontEndBaseURL + '/form/kyc-aml?id=' + userId + '&page=1&formToken=' + formToken;
 
    sendEmailNotification({
      email: formData.email,
      subject: "KYC Form Invitation - iCompass",
      message: `
        <div style="max-width: 600px; margin: 0 auto; border: 2px solid #FF8C00; padding: 20px; text-align: center;">
          <h1 style="margin-bottom: 20px; text-decoration: underline;">KYC Information Request</h1>
          <p> Dear ${formData.name} (Entity) </p>
          <p> ${decodedToken.name} from ${decodedToken.companyName} has requested for you to fill in your details in order to fulfill their KYC/AML obligations.  </p>
          <p> This link is only valid for 48 hours. </p>
          <p> 
            Please use the <a href= '${linkToForm} '> link </a>
            to access the form and fill in the necessary information as requested.
          </p>
          <img src="https://icompass-backend-dev-fhagh5hccpf2ebh9.southeastasia-01.azurewebsites.net/images/from_icompass" alt="iCOMPASS logo" style="display: block; margin: 20px auto; width: 200px; height: auto; max-width: 100%;">
          <p style="font-style: italic; margin-top: 20px;">Please do not reply to this email</p>
        </div> `
    })

     const newFormData = {
      ...formData,  
      emailSent : true,
      maxPage : 1
    } 
    setFormData(newFormData)
    await updatekyc(newFormData, token)
    toast.info(`Form saved, Email notification to ${formData.email} sent!`)
    navigate('/kyc-aml/customer-list')

  }

  const handleSubmitForm = async (e) => { 
    // const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    // setIsValidEmail(emailPattern.test(formData.email));  // Check if the entered email matches the email pattern
    //   for (const key in formData) {
    //         if (formData[key].trim() === '') {
    //             toast.info("Please fill in all fields")
    //             return 
    //         }
    // }

    //1. check mandatory fields filled
    const requiredFields = ["name", "uenNumber", "incorporationCountry", "incorporationDate", "registeredAddress", "contactNumber", "email", "taxResidenceCountry", "natureOfBusiness"];
    let allRequiredFieldsFilled = true
    let newMandatoryFieldError = {...mandatoryFieldError}

    requiredFields.forEach( field => {
      if (!formData[field]) { 
        allRequiredFieldsFilled = false
        newMandatoryFieldError[field] = true 
      } else {
        newMandatoryFieldError[field] = false 
      }
    })
    setMandatoryFieldError(newMandatoryFieldError)

    const requiredConnectedFields = ['name', 'address' , 'gender', 'identificationNumber']
    let newMandatoryConnectedFieldError = []

    formData.connectedParty.forEach((party, partyIndex) => {
      newMandatoryConnectedFieldError.push({ name: '', address: '', gender: '', identificationNumber: '' })
      requiredConnectedFields.forEach((field) => {
        if (!party[field]) { 
          allRequiredFieldsFilled = false;
          newMandatoryConnectedFieldError[partyIndex][field] = true; 
        }
      });
    });
    setMandatoryConnectedFieldError(newMandatoryConnectedFieldError)

    if (!allRequiredFieldsFilled) {
        toast.info("Please fill in the required fields");
        return;
    }

    // 2. save to DB
    const newFormData = {
      ...formData,  
      formStatus : FormStatusEnum.PENDING_RISK_ASSESSMENT_2,  
      maxPage : 2
    } 
    setFormData(newFormData)
    toast.info("Form Submitted!")
    await updatekyc(newFormData, token)

    createNotification({
      type: 'KYC',
      text: `${newFormData.name} status has changed to Pending Risk Assessment`,
      date: DateTime.now().toFormat('dd-MM-yyyy HH:mm:ss')
  }, token)

    if (currentUrl !== '/form/kyc-aml') {
      navigate('/kyc-aml/customer-list')
    } else {
      window.location.reload();
    }
  }


  return (
  <div className='max-w-5xl mx-auto'>
    <div className="max-w-6xl mx-auto mt-3 p-5 bg-white rounded-lg shadow-lg">
      <h2 className="text-xl font-semibold mb-1">KYC Form - {companyName} (Entity) </h2>
    </div>

    <div className={`max-w-6xl mx-auto mt-3 p-5 rounded-lg shadow-lg ${readOnly ? 'bg-gray-200' : 'bg-white '}`}>
    {/* 1st row */}
    <div className="grid grid-cols-2 gap-4 mb-4">
      {/* <input disabled={readOnly}Field type='text' name='entityName' displayName='Name of Entity' value={formData.entityName} handleInputChange={handleInputChange} /> */}
        <div>
          <label className="block font-medium text-gray-700 mb-1">Name of Entity<i className='text-red-600'>*</i></label>
          <input disabled={readOnly} type="text" name='name' placeholder="Enter Entity Name" value={formData.name} onChange={handleInputChange} className="w-11/12 p-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none focus:border-green-500" />
          {mandatoryFieldError.name && <p style={{ color: 'red' }}>Please enter a name</p>}
          </div>
        <div>
          <label className="block font-medium text-gray-700 mb-1">UEN Number / Company Identification Number<i className='text-red-600'>*</i></label>
          <input disabled={readOnly} type="text" id="uenNumber" name='uenNumber' placeholder="Enter UEN Number / Company Identification Number" value={formData.uenNumber} onChange={handleInputChange} className="w-11/12 p-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none focus:border-green-500" />
          {mandatoryFieldError.uenNumber && <p style={{ color: 'red' }}>Please enter UEN Number</p>}
          </div>
      </div>
      {/* 2nd row */}
      <div className="grid grid-cols-2 gap-4 mb-4">
        <div>
          <p> Country of Incorporation </p>
          <CountryList width={'w-11/12'} value={formData.incorporationCountry} name='incorporationCountry' handleInputChange={handleInputChange} disabled={readOnly} />
        </div>
        <div>
          <label htmlFor="incorporationDate" className="block font-medium text-gray-700 mb-1"> Date of Incorporation<i className='text-red-600'>*</i></label>
          <input disabled={readOnly} type="date" id="incorporationDate" value={formData.incorporationDate} name='incorporationDate' onChange={handleInputChange} className="w-11/12 p-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none focus:border-green-500" />
          {mandatoryFieldError.incorporationDate && <p style={{ color: 'red' }}>Please enter incorporation date</p>}
          </div>
      </div>
      {/* 3rd row */}
      <div className="grid grid-cols-2 gap-4 mb-4">
        <div>
          <label className="block font-medium text-gray-700 mb-1">Registered Address<i className='text-red-600'>*</i></label>
          <input disabled={readOnly} type="text" id="registeredAddress" name='registeredAddress' placeholder="Enter Registered Address" value={formData.registeredAddress} onChange={handleInputChange} className="w-11/12 p-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none focus:border-green-500" />
          {mandatoryFieldError.registeredAddress && <p style={{ color: 'red' }}>Please enter registered address</p>}
          </div>
        <div>
          <label htmlFor="contactNumber" className="block font-medium text-gray-700 mb-1">Contact Number<i className='text-red-600'>*</i></label>
          <input disabled={readOnly} type="tel" id="contactNumber" placeholder="Enter Contact Number" name="contactNumber"  value={formData.contactNumber} onChange={handleInputChange} className="w-11/12 p-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none focus:border-green-500" />
          {mandatoryFieldError.contactNumber && <p style={{ color: 'red' }}>Please enter contact number</p>}
          </div>
        {/* <div> another col </div> */}
      </div>
      {/* 4th row */}
      <div className="grid grid-cols-2 gap-4 mb-4"> 
        <div>
          <label htmlFor="email" className="block font-medium text-gray-700 mb-1">Email</label>
          <input disabled={readOnly} type="email" id="email" placeholder="Enter Email" value={formData.email} onChange={handleInputChange} name="email" className="w-11/12 p-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none focus:border-green-500" />
          {mandatoryFieldError.email && <p style={{ color: 'red' }}>Please enter a valid email address</p>}
        </div>
        <div>
          <p> Country of Tax Residence</p>
          <CountryList width={'w-11/12'} value={formData.taxResidenceCountry} name='taxResidenceCountry' handleInputChange={handleInputChange} disabled={readOnly}/>
        </div>
      </div>
      {/* 5th row */}
      <div className="grid gap-4 mb-5 border-green-500 border p-2">
        <div>
          <p className="block font-medium text-gray-700  py-1 px-2 underline">All relevant parties </p>
          <ConnectedOrBeneficialParty mandatoryConnectedFieldError={mandatoryConnectedFieldError} setMandatoryConnectedFieldError={setMandatoryConnectedFieldError} disabled={readOnly} token={token} personType="Relevant Party" formName="connectedParty" formData={formData} setFormData={setFormData} handleInputChange={handleInputChange} isValidEmail={isValidEmail} setIsValidEmail={setIsValidEmail} borderColor={"green"} />
        </div> 
      </div>

      {/* 6th Row */}
      <div className="grid gap-4">
      </div>

      {/* 7th row */}
      <div className="grid grid-cols-2 gap-4 mb-4">
        <div>
           Nature of Business (Principal activities in ACRA)<i className='text-red-600'>*</i>
          <NatureOfBusiness formData={formData} handleInputChange={handleInputChange} disabled={readOnly}/> 
          {mandatoryFieldError.natureOfBusiness && <p style={{ color: 'red' }}>Please enter nature of business</p>}

          <label htmlFor="Account Manager" className="block font-medium text-gray-700 mb-1">Account Manager</label>
          <input disabled={readOnly} id="Account Manager" placeholder="Enter Account Manager" name="accountManager"  value={formData.accountManager} onChange={handleInputChange} className="w-11/12 p-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none focus:border-green-500" />
          </div>

        <div>
          <div className='flex'> 
            <span className='mr-2'> Source of Wealth/Funds </span>   
            <Modal title="Definitions" CustomButton={() => 
              <Tooltip 
                title={ <p> Click to View definitions </p> }
                arrow
                componentsProps={{
                  tooltip: {
                    sx: {
                      maxWidth: "600px",
                      fontSize: "14px",
                    },
                  },
                }}
              >
                <span className='text-xl relative bottom-0.5' > ⓘ </span> 
              </Tooltip>
            }>
              <SourceOfWealthFunds />       
            </Modal>
          </div>
          <textarea disabled={readOnly} name='sourceOfWealth' placeholder="Enter Source of Wealth/Funds" value={formData.sourceOfWealth} onChange={handleInputChange} className="w-11/12 p-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none focus:border-green-500" rows={4}/>
          {/* <input disabled={readOnly} type="text" name='sourceOfWealth' placeholder="Enter Source of Wealth" value={formData.sourceOfWealth} onChange={handleInputChange} className="w-11/12 p-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none focus:border-green-500" /> */}
        </div>



      </div> 


      

      {/* 8th row */}
      <div className="grid grid-cols-2 gap-4 mb-4">
          <div>
            <label className="block font-medium text-gray-700 mb-1">Are you a Singapore Government Entity?</label>
            <div className="mt-1 flex items-center">
              <input disabled={readOnly} type="radio" name="singaporeGovernmentEntity" value="yes" onChange={handleInputChange} checked={formData?.singaporeGovernmentEntity === 'yes'} className="mr-1 w-4 h-4" />
               Yes
              <input disabled={readOnly} type="radio" name="singaporeGovernmentEntity" value="no" onChange={handleInputChange} checked={formData?.singaporeGovernmentEntity === 'no'} className="ml-2 mr-1 w-4 h-4"/>
               No
            </div>
            {/* <div> another col </div> */} 
          </div>
          
        <div>
          <label className="block font-medium text-gray-700 mb-1">Are you a listed entity?</label>
          <div className="mt-1 flex items-center">
            <input disabled={readOnly} type="radio" id="pepYes" name="listedOnStockExchange" value="yes" onChange={handleInputChange} checked={formData.listedOnStockExchange === 'yes'} className="mr-1 w-4 h-4" />
             Yes
            <input disabled={readOnly} type="radio" id="pepNo" name="listedOnStockExchange" value="no" onChange={handleInputChange} checked={formData.listedOnStockExchange === 'no'} className="ml-2 mr-1 w-4 h-4"/>
             No
          </div>
        </div>
      </div>
      {/* 9th row */}
      <div className="grid grid-cols-2 gap-4 mb-4">
          <div>
            <label className="block font-medium text-gray-700 mb-1">Are you a financial institution?</label>
            <div className="mt-1 flex items-center">
              <input disabled={readOnly} type="radio" name="financialInstitution" value="yes" onChange={handleInputChange} checked={formData.financialInstitution === 'yes'} className="mr-1 w-4 h-4" />
               Yes
              <input disabled={readOnly} type="radio"name="financialInstitution" value="no" onChange={handleInputChange} checked={formData.financialInstitution === 'no'} className="ml-2 mr-1 w-4 h-4"/>
               No
            </div>
          </div>
          <div>
            <div>
              <p> Certificate of Incumbency </p>
              {formData?.incumbencyCertificateUrl && (
                <>
                 <a href={`${process.env.REACT_APP_BACKEND_URL}/get-upload?fileName=${formData.incumbencyCertificateUrl.split('/').slice(-1)[0]}&accessToken=${token}`} target='_blank'  rel="noopener noreferrer" className='text-blue-600 mr-2'> {formData?.incumbencyCertificateUrl.split('/').slice(-1)[0]} </a> 
                 <span className='cursor-pointer' onClick={() => handleFileRemove('incumbencyCertificateUrl')}> ❌ </span> 
                 </>
                )}
              <label className="cursor-pointer bg-gray-200 px-1 py-0.5 mt-1 rounded">
                Browse
                <input disabled={readOnly} type="file" name="incumbencyCertificateUrl" onChange={(e) => handleFileUpload(e, 'incumbencyCertificateUrl')} className="hidden"/>
              </label>
            </div>
          </div>
        </div>
        {/* 10th row */}
      <div className="grid grid-cols-2 gap-4 mb-4">
        <div>
          <p> Company Constitution </p>
          {formData?.companyConstitution && (
            <>
              <a href={`${process.env.REACT_APP_BACKEND_URL}/get-upload?fileName=${formData.companyConstitution.split('/').slice(-1)[0]}&accessToken=${token}`} target='_blank'  rel="noopener noreferrer" className='text-blue-600 mr-2'> {formData?.companyConstitution.split('/').slice(-1)[0]} </a> 
              <span className='cursor-pointer' onClick={() => handleFileRemove('companyConstitution')}> ❌ </span> 
            </>
          )}
          <label className="cursor-pointer bg-gray-200 px-1 py-0.5 mt-1 rounded">
            Browse
            <input disabled={readOnly} type="file"  name="companyConstitution" onChange={(e) => handleFileUpload(e, 'CompanyConstitutionLink')} className="hidden"/>
          </label>
        </div>
        <div>
          <p> Organization Chart </p>
          {formData?.organisationChart && (
            <>
             <a href={`${process.env.REACT_APP_BACKEND_URL}/get-upload?fileName=${formData.organisationChart.split('/').slice(-1)[0]}&accessToken=${token}`} target='_blank'  rel="noopener noreferrer" className='text-blue-600 mr-2'> {formData?.organisationChart.split('/').slice(-1)[0]} </a> 
             <span className='cursor-pointer' onClick={() => handleFileRemove('organisationChart')}> ❌ </span> 
            </>
             )}
        <label className="cursor-pointer bg-gray-200 px-1 py-0.5 mt-1 rounded">
          Browse
          <input disabled={readOnly} type="file" name="organisationChart" onChange={(e) => handleFileUpload(e, 'OrganizationChartLink')} className="hidden"/>
        </label>
        </div>
      </div>


      <br/><hr/><br/>
      { currentUrl !== '/form/kyc-aml' && !readOnly && (
        <div className='my-4 flex justify-between'>
          <button onClick={handleSaveForm} disabled={readOnly} className='ml-4 bg-green-500 text-white font-semibold py-2 px-4 rounded-md hover:bg-green-600'>
            Save Form 
          </button>
 
          <p>
          <button onClick={handleSendEmail} disabled={readOnly} className='ml-4 bg-green-500 text-white font-semibold py-2 px-4 rounded-md hover:bg-green-600'>
            Send Notification Email
          </button> 
 
          { !formData?.emailSent && (
            <button onClick={handleSubmitForm} disabled={readOnly} className='ml-4 bg-green-500 text-white font-semibold py-2 px-4 rounded-md hover:bg-green-600'>
              Submit
          </button>  
          )}

          { formData?.emailSent && (
            <Tooltip title={<p style={{ fontSize: "15px" }}>Email Notification sent, awaiting customer submission.</p>} arrow className='ml-4 bg-green-300 text-white font-semibold py-2 px-4 rounded-md '>
              <button onClick={handleSubmitForm} disabled={true}  className='ml-4'> Submit </button>  
          </Tooltip> 
            )}

          </p>
        </div>
        )
      }

      {currentUrl === '/form/kyc-aml' && !readOnly && (
        <div className='my-4 flex justify-between'>
          <button onClick={handleSaveForm} className='ml-4 bg-green-500 text-white font-semibold py-2 px-4 rounded-md hover:bg-green-600'> 
            Save Form
          </button>
        <p> 
          <button onClick={handleSubmitForm} className='ml-4 bg-green-500 text-white font-semibold py-2 px-4 rounded-md hover:bg-green-600'> 
            Submit
          </button>
        </p>
      </div>
      )}

    </div> <br/> <br/> <br/>
</div>
  );
};
export default _EntityDetails;



const SourceOfWealthFunds = ({formData, handleInputChange,disabled }) => {
  return (
    <div className='p-6 w-6xl'>
      <p>
        <strong> Source of wealth: </strong> generally refers to the origin of the customer’s and beneficial owner’s entire body of wealth (i.e. total assets). This relates to how the customer and beneficial owner of the customer have acquired the wealth which is distinct from identifying the assets that they own. <br/> <br/>
        Source of wealth information should give an indication about the size of wealth the customer and beneficial owner would be expected to have, and how the customer and beneficial owner acquired the wealth. Although the CMI may not have specific information about assets that are not deposited with or processed by the CMI, it may be possible to obtain general information from the customer, commercial databases or other open sources. <br/><br/>
        Examples of appropriate and reasonable means of establishing source of wealth are information and documents such as evidence of title, copies of trust deeds, audited accounts, salary details, tax returns and bank statements. <br/><br/>
      </p>
      <hr/> <br/>
      <p>
        <strong> Source of funds: </strong> refers to the origin of the particular funds or other assets which are the subject of the establishment of business relations (e.g. the amounts being invested, deposited, or wired as part of the business relations). <br/><br/>
        In order to ensure that the funds are not proceeds of crime, the CMI should not limit its source of funds inquiry to identifying the other FI from which the funds have been transferred, but more importantly, the activity that generated the funds.  <br/><br/>
        The information obtained should be substantive and facilitate the establishment of the provenance of the funds or reason for the funds having been acquired. Examples of appropriate and reasonable means of establishing source of funds are information such as salary payments or sale proceeds. <br/> 
      </p>
    </div>
  )
}


const NatureOfBusiness = ({formData, handleInputChange,disabled }) => {
  return (
  <Tooltip title={formData?.natureOfBusiness?.slice(5)} leaveDelay={700} arrow>
    <select disabled={disabled} value={formData.natureOfBusiness} onChange={handleInputChange} name='natureOfBusiness'className="w-11/12 p-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none mb-1 focus:border-green-500">
      <option value=""> - Please Select - </option>
      <option value="No - Agricultural Sector">Agricultural Sector</option>
      <option value="No - Quarrying of Stone, Sand and Clay">Quarrying of Stone, Sand and Clay</option>
      <option value="No - Other Mining and Quarrying">Other Mining and Quarrying</option>
      <option value="No - Service Activities Incidental to Oil and Gas Extraction (excluding Surveying and Engineering Design and Consultancy Services Supporting Mining, Oil and Gas Extraction and Offshore Exploration Activities)" style={{ whiteSpace: 'pre-wrap'}}> Service Activities Incidental to Oil and Gas Extraction (excluding Surveying and Engineering Design and Consultancy Services Supporting Mining,  Oil and Gas Extraction and Offshore Exploration Activities) </option>
      <option value="No - Food processing">Food processing</option>
      <option value="No - Manufacturing Sector">Manufacturing Sector</option>
      <option value="No - Printing">Printing</option>
      <option value="No - Service Activities Related to Printing">Service Activities Related to Printing</option>
      <option value="No - Reproduction of Recorded Media">Reproduction of Recorded Media</option>
      <option value="No - Cutting, Shaping and Finishing of Stone">Cutting, Shaping and Finishing of Stone</option>
      <option value="No - Casting of Iron and Steel">Casting of Iron and Steel</option>
      <option value="No - Casting of Non-Ferrous Metals">Casting of Non-Ferrous Metals</option>
      <option value="No - Forging, Pressing, Stamping and Roll-Forming of Metal; Powder Metallurgy">Forging, Pressing, Stamping and Roll-Forming of Metal; Powder Metallurgy</option>
      <option value="No - Treatment and Coating of Metals">Treatment and Coating of Metals</option>
      <option value="No - Installation of Industrial Machinery and Equipment">Installation of Industrial Machinery and Equipment</option>
      <option value="No - Building and Repairing of Ships and Floating Structures">Building and Repairing of Ships and Floating Structures</option>
      <option value="No - Building of Pleasure Boats and Sporting Boats">Building of Pleasure Boats and Sporting Boats</option>
      <option value="No - Electric Power Generation, Transmission and Distribution">Electric Power Generation, Transmission and Distribution</option>
      <option value="No - Manufacture of Gas; Distribution of Gaseous Fuels Through Mains">Manufacture of Gas; Distribution of Gaseous Fuels Through Mains</option>
      <option value="No - Supply of Air or Water for Cooling or Heating Purposes">Supply of Air or Water for Cooling or Heating Purposes</option>
      <option value="No - Water Collection, Treatment and Supply">Water Collection, Treatment and Supply</option>
      <option value="No - Sewerage">Sewerage</option>
      <option value="No - Collection of Waste">Collection of Waste</option>
      <option value="No - Treatment and Disposal of Waste (including Remediation Activities)">Treatment and Disposal of Waste (including Remediation Activities)</option>
      <option value="No - Materials Recovery">Materials Recovery</option>
      <option value="No - Construction of Buildings">Construction of Buildings</option>
      <option value="No - Construction of Roads and Railways">Construction of Roads and Railways</option>
      <option value="No - Construction of Utility Projects">Construction of Utility Projects</option>
      <option value="No - Construction of Other Civil Engineering Projects">Construction of Other Civil Engineering Projects</option>
      <option value="No - Demolition">Demolition</option>
      <option value="No - Site Preparation">Site Preparation</option>
      <option value="No - Electrical Installation">Electrical Installation</option>
      <option value="No - Plumbing, Heat and Air-Conditioning Installation">Plumbing, Heat and Air-Conditioning Installation</option>
      <option value="No - Other Construction Installation">Other Construction Installation</option>
      <option value="No - Building Completion and Finishing">Building Completion and Finishing</option>
      <option value="No - Other Specialised Construction Activities">Other Specialised Construction Activities</option>
      <option value="No - Wholesale Retailer">Wholesale Retailer</option>
      <option value="No - Ship Chandlers and Ship Bunkering">Ship Chandlers and Ship Bunkering</option>
      <option value="No - Non-specialised Wholesale Trade">Non-specialised Wholesale Trade</option>
      <option value="No - Retail Sales">Retail Sales</option>
      <option value="No - Passenger and Freight Rail Transport">Passenger and Freight Rail Transport</option>
      <option value="No - Passenger Land Transport">Passenger Land Transport</option>
      <option value="No - Aerial Cableways">Aerial Cableways</option>
      <option value="No - Freight Land Transport">Freight Land Transport</option>
      <option value="No - Transport via Pipelines">Transport via Pipelines</option>
      <option value="No - Passenger Water Transport">Passenger Water Transport</option>
      <option value="No - Freight Water Transport">Freight Water Transport</option>
      <option value="No - Air Transport">Air Transport</option>
      <option value="No - Warehousing and Storage">Warehousing and Storage</option>
      <option value="No - Supporting Services to Land Transport">Supporting Services to Land Transport</option>
      <option value="No - Supporting Services to Water Transport">Supporting Services to Water Transport</option>
      <option value="No - Supporting Services to Air Transport">Supporting Services to Air Transport</option>
      <option value="No - Cargo Handling">Cargo Handling</option>
      <option value="No - Marine Surveying Services and Classification Societies">Marine Surveying Services and Classification Societies</option>
      <option value="No - Other Transportation Support Activities">Other Transportation Support Activities</option>
      <option value="No - National Post Activities">National Post Activities</option>
      <option value="No - Courier Activities Other than National Post Activities">Courier Activities Other than National Post Activities</option>
      <option value="No - Short Term Accommodation Activities">Short Term Accommodation Activities</option>
      <option value="No - Hostels and dormitories for students, workers and other individuals">Hostels and dormitories for students, workers and other individuals</option>
      <option value="No - Restaurants and Cafes">Restaurants and Cafes</option>
      <option value="No - Fast Food Outlets, Food Courts and Food Kiosks">Fast Food Outlets, Food Courts and Food Kiosks</option>
      <option value="No - Pubs">Pubs</option>
      <option value="No - Stalls Selling Cooked Food and Prepared Drinks">Stalls Selling Cooked Food and Prepared Drinks</option>
      <option value="No - Food Caterers">Food Caterers</option>
      <option value="No - Book Publishing">Book Publishing</option>
      <option value="No - Publishing of Directories and Mailing Lists">Publishing of Directories and Mailing Lists</option>
      <option value="No - Publishing of News, Journals and Periodicals">Publishing of News, Journals and Periodicals</option>
      <option value="No - Other Publishing Activities">Other Publishing Activities</option>
      <option value="No - Software Publishing">Software Publishing</option>
      <option value="No - Movie, Video, Television and Other Programme Production Activities">Movie, Video, Television and Other Programme Production Activities</option>
      <option value="No - Movie, Video, Television and Other Programme Post-Production Activities">Movie, Video, Television and Other Programme Post-Production Activities</option>
      <option value="No - Movie, Video, Television and Other Programme Distribution Activities">Movie, Video, Television and Other Programme Distribution Activities</option>
      <option value="No - Movie Projection Activities">Movie Projection Activities</option>
      <option value="No - Sound Recording and Music Publishing Activities">Sound Recording and Music Publishing Activities</option>
      <option value="No - Radio Programme Production and Broadcasting">Radio Programme Production and Broadcasting</option>
      <option value="No - Television Programming and Broadcasting">Television Programming and Broadcasting</option>
      <option value="No - Telecommunications Network Operation">Telecommunications Network Operation</option>
      <option value="No - Other Telecommunications Activities">Other Telecommunications Activities</option>
      <option value="No - Computer Programming Activities">Computer Programming Activities</option>
      <option value="No - Information Technology Consultancy and Computer Facilities Management Activities">Information Technology Consultancy and Computer Facilities Management Activities</option>
      <option value="No - Other Information Technology and Computer Service Activities">Other Information Technology and Computer Service Activities</option>
      <option value="No - Data Analytics Processing, Hosting and Related Activities">Data Analytics Processing, Hosting and Related Activities</option>
      <option value="No - Internet Search Engines">Internet Search Engines</option>
      <option value="No - Online Marketplaces">Online Marketplaces</option>
      <option value="No - Other Information Service Activities">Other Information Service Activities</option>
      <option value="No - Central Bank">Central Bank</option>
      <option value="Yes - Full Banks">Full Banks</option>
      <option value="Yes - Wholesale Banks">Wholesale Banks</option>
      <option value="Yes - Merchant Banks">Merchant Banks</option>
      <option value="Yes - Finance Companies">Finance Companies</option>
      <option value="Yes - Other Monetary Intermediation">Other Monetary Intermediation</option>
      <option value="Yes - Activities of Holding Companies">Activities of Holding Companies</option>
      <option value="Yes - Trusts, Funds, and Similar Financial Entities">Trusts, Funds, and Similar Financial Entities</option>
      <option value="No - Financial Leasing">Financial Leasing</option>
      <option value="No - Other Credit Granting">Other Credit Granting</option>
      <option value="No - Financial Service Activities, except Insurance and Pension Funding Activities n.e.c.">Financial Service Activities, except Insurance and Pension Funding Activities n.e.c.</option>
      <option value="Yes - Life Insurance (Insurance Underwriting)">Life Insurance (Insurance Underwriting)</option>
      <option value="Yes - General Insurance (Insurance Underwriting)">General Insurance (Insurance Underwriting)</option>
      <option value="No - Reinsurance">Reinsurance</option>
      <option value="No - Provident and Pension Funding">Provident and Pension Funding</option>
      <option value="No - Securities and commodities exchange">Securities and commodities exchange</option>
      <option value="Yes - Security and Commodity Contracts Brokerage">Security and Commodity Contracts Brokerage</option>
      <option value="No - Other Activities Auxiliary to Financial Service Activities">Other Activities Auxiliary to Financial Service Activities</option>
      <option value="No - Risk and Damage Evaluation">Risk and Damage Evaluation</option>
      <option value="No - Activities of Insurance Intermediaries">Activities of Insurance Intermediaries</option>
      <option value="No - Other Activities Auxiliary to Insurance and Pension Funding">Other Activities Auxiliary to Insurance and Pension Funding</option>
      <option value="No - Fund Management Activities">Fund Management Activities</option>
      <option value="No - Real Estate Activities with Own or Leased Property">Real Estate Activities with Own or Leased Property</option>
      <option value="No - Real Estate Activities on a Fee or Commission Basis">Real Estate Activities on a Fee or Commission Basis</option>
      <option value="No - Legal Activities">Legal Activities</option>
      <option value="No - Accounting, Book-Keeping and Auditing Activities; Tax Consultancy">Accounting, Book-Keeping and Auditing Activities; Tax Consultancy</option>
      <option value="No - Activities of Head Offices; Centralised Administrative Offices and Business Representative Offices">Activities of Head Offices; Centralised Administrative Offices and Business Representative Offices</option>
      <option value="No - Management Consultancy Activities">Management Consultancy Activities</option>
      <option value="No - Architectural and Surveying Activities">Architectural and Surveying Activities</option>
      <option value="No - Engineering Design and Consultancy Activities">Engineering Design and Consultancy Activities</option>
      <option value="No - Technical Testing and Analysis Services">Technical Testing and Analysis Services</option>
      <option value="No - Research and Experimental Development on Natural Sciences and Engineering">Research and Experimental Development on Natural Sciences and Engineering</option>
      <option value="No - Research and Experimental Development on Social Sciences and Humanities">Research and Experimental Development on Social Sciences and Humanities</option>
      <option value="No - Advertising">Advertising</option>
      <option value="No - Market Research and Public Opinion Polling">Market Research and Public Opinion Polling</option>
      <option value="No - Industrial Design Activities">Industrial Design Activities</option>
      <option value="No - Other Specialised Design Activities">Other Specialised Design Activities</option>
      <option value="No - Photographic Activities">Photographic Activities</option>
      <option value="No - Professional, Scientific and Technical Activities n.e.c.">Professional, Scientific and Technical Activities n.e.c.</option>
      <option value="No - Veterinary Activities">Veterinary Activities</option>
      <option value="No - Renting and Leasing of Land Transport Equipment">Renting and Leasing of Land Transport Equipment</option>
      <option value="No - Renting and Leasing of Recreational and Sports Goods">Renting and Leasing of Recreational and Sports Goods</option>
      <option value="No - Renting of VCDs and DVDs">Renting of VCDs and DVDs</option>
      <option value="No - Renting and Leasing of Other Personal and Household Goods">Renting and Leasing of Other Personal and Household Goods</option>
      <option value="No - Renting of Water Transport Equipment">Renting of Water Transport Equipment</option>
      <option value="No - Renting of Air Transport Equipment">Renting of Air Transport Equipment</option>
      <option value="No - Renting of Construction and Civil Engineering Machinery and Equipment">Renting of Construction and Civil Engineering Machinery and Equipment</option>
      <option value="No - Renting of Office Machinery and Equipment (including Computers)">Renting of Office Machinery and Equipment (including Computers)</option>
      <option value="No - Renting of Machinery, Equipment and Tangible Goods n.e.c.">Renting of Machinery, Equipment and Tangible Goods n.e.c.</option>
      <option value="No - Leasing of Non-Financial Intangible Assets">Leasing of Non-Financial Intangible Assets</option>
      <option value="No - Employment and Recruitment Agencies">Employment and Recruitment Agencies</option>
      <option value="No - Management of Human Resource Functions">Management of Human Resource Functions</option>
      <option value="No - Travel Agencies and Tour Operators Activities">Travel Agencies and Tour Operators Activities</option>
      <option value="No - Timesharing Service Activities">Timesharing Service Activities</option>
      <option value="No - Private Security Activities">Private Security Activities</option>
      <option value="No - Detective and Investigation Activities">Detective and Investigation Activities</option>
      <option value="No - General Cleaning Services">General Cleaning Services</option>
      <option value="No - Other Cleaning Services">Other Cleaning Services</option>
      <option value="No - Landscape Planting, Care and Maintenance Service Activities">Landscape Planting, Care and Maintenance Service Activities</option>
      <option value="No - Combined Office Administrative Service Activities">Combined Office Administrative Service Activities</option>
      <option value="No - Photocopying, Document Preparation and Other Specialised Office Support Activities">Photocopying, Document Preparation and Other Specialised Office Support Activities</option>
      <option value="No - Activities of Call Centres">Activities of Call Centres</option>
      <option value="No - Convention, Trade Show, Concert and Other Event Organisers">Convention, Trade Show, Concert and Other Event Organisers</option>
      <option value="No - Activities of Collection Agencies and Credit Bureaus">Activities of Collection Agencies and Credit Bureaus</option>
      <option value="No - Packaging Activities">Packaging Activities</option>
      <option value="No - Other Business Support Service Activities n.e.c.">Other Business Support Service Activities n.e.c.</option>
      <option value="No - General Public Administration">General Public Administration</option>
      <option value="No - Public Administration of Economic and Social Programmes">Public Administration of Economic and Social Programmes</option>
      <option value="No - Foreign Affairs">Foreign Affairs</option>
      <option value="No - Defence Activities">Defence Activities</option>
      <option value="No - Public Order and Safety Activities">Public Order and Safety Activities</option>
      <option value="No - Pre-Primary and Primary Education">Pre-Primary and Primary Education</option>
      <option value="No - General Secondary and Post-Secondary Non-Tertiary Education">General Secondary and Post-Secondary Non-Tertiary Education</option>
      <option value="No - Technical and Vocational (Secondary and Post-Secondary Non-Tertiary) Education">Technical and Vocational (Secondary and Post-Secondary Non-Tertiary) Education</option>
      <option value="No - International and Foreign System Schools">International and Foreign System Schools</option>
      <option value="No - Schools for Special Needs">Schools for Special Needs</option>
      <option value="No - Specialised Education Schools">Specialised Education Schools</option>
      <option value="No - Higher Education">Higher Education</option>
      <option value="No - Other Education">Other Education</option>
      <option value="No - Educational Support Services">Educational Support Services</option>
      <option value="No - Hospital Activities">Hospital Activities</option>
      <option value="No - Medical and Dental Practice Activities">Medical and Dental Practice Activities</option>
      <option value="No - Other Health Services">Other Health Services</option>
      <option value="No - Residential Nursing Care Facilities">Residential Nursing Care Facilities</option>
      <option value="No - Other Residential Care Services">Other Residential Care Services</option>
      <option value="No - Social Services Without Accommodation for the Elderly and Disabled">Social Services Without Accommodation for the Elderly and Disabled</option>
      <option value="No - Social Services Without Accommodation for Children, Youth and Families">Social Services Without Accommodation for Children, Youth and Families</option>
      <option value="No - Community Activities">Community Activities</option>
      <option value="No - Social Services Without Accommodation n.e.c.">Social Services Without Accommodation n.e.c.</option>
      <option value="No - Creative, Arts and Entertainment Activities">Creative, Arts and Entertainment Activities</option>
      <option value="No - Libraries and Archives Activities">Libraries and Archives Activities</option>
      <option value="No - Museums Activities and Operation of Historical Sites and Buildings">Museums Activities and Operation of Historical Sites and Buildings</option>
      <option value="No - Botanical and Zoological Gardens and Nature Reserves Activities">Botanical and Zoological Gardens and Nature Reserves Activities</option>
      <option value="Yes - Gambling and Betting Activities">Gambling and Betting Activities</option>
      <option value="No - Operation of Sports Facilities">Operation of Sports Facilities</option>
      <option value="No - Activities of Sports Clubs/Associations and Related Activities">Activities of Sports Clubs/Associations and Related Activities</option>
      <option value="No - Other Amusement and Recreation Activities">Other Amusement and Recreation Activities</option>
      <option value="No - Activities of Business and Employers' Membership Organisations">Activities of Business and Employers' Membership Organisations</option>
      <option value="No - Activities of Professional Membership Organisations">Activities of Professional Membership Organisations</option>
      <option value="No - Activities of Trade Unions">Activities of Trade Unions</option>
      <option value="No - Activities of Religious Organisations">Activities of Religious Organisations</option>
      <option value="No - Activities of Political Organisations">Activities of Political Organisations</option>
      <option value="No - Activities of Other Membership Organisations n.e.c.">Activities of Other Membership Organisations n.e.c.</option>
      <option value="No - Repair of Computers and Peripheral Equipment">Repair of Computers and Peripheral Equipment</option>
      <option value="No - Repair of Communications Equipment">Repair of Communications Equipment</option>
      <option value="No - Repair of Consumer Electronics">Repair of Consumer Electronics</option>
      <option value="No - Repair of Household Appliances and Home and Garden Equipment">Repair of Household Appliances and Home and Garden Equipment</option>
      <option value="No - Repair of Footwear and Leather Goods">Repair of Footwear and Leather Goods</option>
      <option value="No - Repair of Furniture and Home Furnishings">Repair of Furniture and Home Furnishings</option>
      <option value="No - Repair of Other Personal and Household Goods">Repair of Other Personal and Household Goods</option>
      <option value="No - Repair and Maintenance (including Inspection) of Vehicles">Repair and Maintenance (including Inspection) of Vehicles</option>
      <option value="No - Washing and (Dry) Cleaning of Textile and Fur Products">Washing and (Dry) Cleaning of Textile and Fur Products</option>
      <option value="No - Hairdressing, Beauty and Other Personal Care Services">Hairdressing, Beauty and Other Personal Care Services</option>
      <option value="No - Funeral and Related Activities">Funeral and Related Activities</option>
      <option value="No - Wedding and Related Activities">Wedding and Related Activities</option>
      <option value="No - Other Personal Service Activities n.e.c.">Other Personal Service Activities n.e.c.</option>
      <option value="No - Activities of Households as Employers of Domestic Personnel">Activities of Households as Employers of Domestic Personnel</option>
      <option value="No - Foreign Embassies and Trade Representative Offices">Foreign Embassies and Trade Representative Offices</option>
      <option value="No - Foreign Armed Forces">Foreign Armed Forces</option>
      <option value="No - Other Extra-territorial Organisations and Bodies">Other Extra-territorial Organisations and Bodies</option>
    </select>
  </Tooltip>
  )
} 