import React, {useState, useEffect} from 'react';
import iCompass_icon from '../../images/iCompass_icon.png'
import { verifyOtp, updateUserOtp, updateForgetPassword } from '../../API/Api';
import { Link, useLocation } from 'react-router-dom';

function ResetPassword({setForgetPassword}) {
  const [ formStage, setFormStage ] = useState('enterEmail')
  const [ wrongCode, setWrongCode ] = useState(false)
  const [ weakPasswordError, setWeakPasswordError ] = useState('')

  const currentLocation = useLocation();
  const queryParams = new URLSearchParams(currentLocation.search);
  const email = queryParams.get('email'); 
  
  const [formData, setFormData] = useState({
    email: email, 
    otp: '',
    newPassword: ''
  })
// switch to react-hook-form and just reuse the logic there for pw validation

  const handleInputChange = (e) => {
    setFormData(prevState => ({
        ...prevState,
        [e.target.name] : e.target.value // name refer to 'input name' property.
    }))
  }

  const handleSendCode = async () => {
    updateUserOtp(formData)
    setFormStage('verifyCode')
  }
     
  const handleVerifyOTP = async () => { 
    setWrongCode(false)
    const result = await verifyOtp(formData) 

    if (result.data.otpMatch === true) {
      setFormStage('newPassword')
    } else {
      setWrongCode(true)
    }
  }
 
  const handleUpdatePassword = async() => { 
    setWeakPasswordError('') 

    if (formData.newPassword.length < 8) { 
      setWeakPasswordError('Password needs at least 8 characters');
      return;
    }
    if (!/[a-z]/.test(formData.newPassword)) { 
      setWeakPasswordError('Password needs at least 1 lowercase'); 
      return;
    }
    if (!/[A-Z]/.test(formData.newPassword)) { 
      setWeakPasswordError('Password needs at least 1 uppercase'); 
      return;
    }
    if (!/\d/.test(formData.newPassword)) { 
      setWeakPasswordError('Password needs at least one digit (0-9)'); 
      return;
    }
    if (!/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(formData.newPassword)) { 
      setWeakPasswordError('Password needs at least 1 special character (punctuation)'); 
      return;
    }

    try {
      await updateForgetPassword(formData)
      window.location.href = '/';
    } catch(err) {
      console.log('err')
    }
  }

  if (localStorage.getItem('accessToken')) { return <div> Please log out to reset password! </div> }


  return (
  <div className="relative w-full h-screen bg-zinc-800/90">
    <div className='flex justify-center items-center h-full shadow-md'>

    {/* Step1, enter email  */}
    {formStage === 'enterEmail' && (
        <div className='max-w-[400px] w-full mx-auto bg-white p-8 shadow-md'>
          <img src={iCompass_icon} className='m-auto w-3/4 mb-5'/> 
          <div className='flex flex-col mb-4'>
            <span className='text-2xl text-center pb-5'> Reset Password </span>
            <input name='email'className='border relative bg-gray-100 p-2' type="text" value={formData.email} placeholder='Enter email linked to your account' onChange={handleInputChange}/>
          </div>

          <button onClick={handleSendCode} className='w-full py-3 mb-5 bg-green-500 hover:bg-green-600 relative text-white'> Send Code </button>
          <Link to='/' replace={currentLocation.pathname === '/'}>
            <button className='text-blue-500 text-sm border mt-2 p-1 hover:bg-gray-200'> Back to login</button>
          </Link>

        </div>
    )}


    {/* Step2, verify code | initial password registration page */}
    {formStage === 'verifyCode' && (
        <div className='max-w-[400px] w-full mx-auto bg-white p-8 shadow-md'>
          <div className='flex flex-col mb-4'>
            <label className='text-2xl text-center pb-5'> Verify Code</label>
            <input name='otp' className='border relative bg-gray-100 p-2' type="text" placeholder='Enter code recieved in email' onChange={handleInputChange}/>
            {wrongCode && <div className="max-w-sm rounded overflow-hidden bg-red-100 text-center mt-5 p-2"> Incorrect Code </div>}
          </div>
          <div className="max-w-sm rounded overflow-hidden bg-green-100 mb-4 px-5">
            <p className="text-gray-700 py-4 text-center"> Code to reset password has been sent to your email </p>
          </div> 
          <button onClick={handleVerifyOTP} className='w-full py-3 mb-5 bg-green-500 hover:bg-green-600 relative text-white'>Verify Code</button>
        </div>
      )}


    {/* Step3, Set New Password */}
      {formStage === 'newPassword' && (
          <div className='max-w-[400px] w-full mx-auto bg-white p-8 shadow-md'>
            <div className='flex flex-col mb-4'>
              <label className='text-2xl text-center pb-5'> New Password</label>
              <input name='newPassword' type='password' className='border relative bg-gray-100 p-2' placeholder='Enter new password' onChange={handleInputChange}/>
            </div>
            <div className='my-4 text-red-500 font-semibold'>
              {weakPasswordError}
            </div>
            <div>
              • At least 8 characters <br/>
              • At least 1 lowercase (a-z) <br/>
              • At least 1 uppercase (A-Z) <br/>
              • At least one digit (0-9) <br/>
              • At least 1 special character (punctuation)  <br/>
            </div>

            <button  onClick={handleUpdatePassword} className='w-full py-3 mb-5 bg-green-500 hover:bg-green-600 relative text-white'>Update Password</button>
          </div>
        )}
      </div>
  </div>
)}

export default ResetPassword;