// import SignatureCanvas from 'react-signature-canvas';
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { getkyc, updatekyc, uploadFile,generateFormToken, sendEmailNotification, createNotification , frontEndBaseURL } from "../../../API/Api"
import { toast } from 'react-toastify';
import { jwtDecode } from "jwt-decode";
import FormStatusEnum from '../../../data/FormStatusEnum';
import CountryList from './CountryList'                  
import { Tooltip } from '@mui/material';
import { DateTime } from 'luxon';
import Modal from "../../shared/Modal";

const _IndividualDetails = ({ individualName,  companyId, userId, readOnly, currentUrl, maxPage, token }) => {
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [formData, setFormData] = useState({
    _id : userId, name: '', gender: '', identificationNumber: '', identificationType: '', identificationDocumentLink : '', identificationExpiryDate: '', contactNumber: '',
    email: '', dateOfBirth: '', address: '', sourceOfWealth: '', residenceCountry: 'SG', nationality: 'SG', occupation: '', formStatus: '',
    addressProofURL: '', emailSent: '',
    // secondNationality: '',
    pepPerson: 'No', pepPersonCountryOrOrganisation: '', pepPersonPublicFunctionNature: '', pepPersonRoleFrom: '', pepPersonRoleTo: '',
    pepAssociate: 'No', pepAssociateRelationship: '',  pepAssociateName: '', pepAssociateCountryOrOrganisation: '', pepAssociatePublicFunctionNature: '',pepAssociateRoleFrom: '', pepAssociateRoleTo: '',
    pepFamily: 'No', pepFamilyRelationship: '', pepFamilyName: '', pepFamilyCountryOrOrganisation: '', pepFamilyPublicFunctionNature: '', pepFamilyRoleFrom: '', pepFamilyRoleTo: '',
    declarationName: '', declarationDate: '', signatureUrl: '', accountManager: ''
  }); //How do you setState in nested field
  
  const [mandatoryFieldError, setMandatoryFieldError] = useState({
    name: '', address: '', gender: '', identificationNumber: ''
  });  

  const decodedToken = jwtDecode(token)

  const navigate = useNavigate() 
  useEffect(() => {
    const fetchData = async () => { 
        const userData = await getkyc(userId, token)  
        setFormData({          
          _id : userId,
          name: userData.data.name,
          email : userData.data.email,
          formStatus : userData.data.formStatus,
          ...userData.data?.details,
          residenceCountry : userData.data?.details?.residenceCountry || "SG",
          nationality : userData.data?.details?.nationality || "SG", 
        }) 
        // signatureRef = useRef()
      }
      fetchData()
  }, []);   

  const handleFileUpload = async (e, fileType) => {
    const { name } = e.target;
    const file = e.target.files[0];
    if (!file) { return }

    try {
      const data = new FormData();
      data.append("file", file);
      const filename = `${fileType}_${file.name}`;
 
      const res = await uploadFile(data, filename, token); 
      setFormData(prevState => ({
        ...prevState,
        [name] : res.data.url
        // identificationDocumentLink :  res.data.url // name refer to 'input name' property.
    }))

    } catch (e) {
      toast.error("Invalid file type!") 
    }
  }


  const identificationDocumentLinkRef = useRef(null)
  const addressProofURLRef = useRef(null)

  const handleFileRemove = async (fileName, ref) => {
    if (readOnly) {return}
    setFormData(prevState => ({
      ...prevState,
      [fileName] : ''
    }))

    ref.current.value = '';
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target; 
    setFormData(prevState => ({
        ...prevState,
        [name] : value // name refer to 'input name' property.
    }))

    const requiredFields = ["name", 'address', 'gender', 'identificationNumber']
    if (!requiredFields.includes(name)) { return }

    let newMandatoryFieldError = { ...mandatoryFieldError };
    if (value.trim() === "") { newMandatoryFieldError[name] = true }
    else { newMandatoryFieldError[name] = false }

    setMandatoryFieldError(newMandatoryFieldError);
  }
  // const handleClearSignature = () => { signatureRef.current.clear(); setFormData(newData) };

  const handleSaveForm = async (e) => { 
    // let signatureData = await signatureRef.current.toDataURL(); // Use the signature data (base64 image) as needed 
    const newFormData = {
      ...formData,  
      maxPage : 1
    } 
    setFormData(newFormData)
    toast.info("Form Saved!") 

    await updatekyc(newFormData, token)
    if (currentUrl !== '/form/kyc-aml') {
      navigate('/kyc-aml/customer-list')
    }  
  }   

  const handleSendEmail = async () => { 

    const formTokenData = {
      name: formData.name,
      userId : userId,
      companyId : jwtDecode(token).companyId,
      // allowedMethods : ['getkyc', 'updatekyc', '/health']
    }

    const formToken = (await generateFormToken(formTokenData, token)).data.accessToken  
    const linkToForm = "https://" + frontEndBaseURL + '/form/kyc-aml?id=' + userId + '&page=1&formToken=' + formToken;
 
    sendEmailNotification({
      email: formData.email,
      subject: "KYC Form Invitation - iCompass",
      message: `
        <div style="max-width: 600px; margin: 0 auto; border: 2px solid #FF8C00; padding: 20px; text-align: center;">
          <h1 style="margin-bottom: 20px; text-decoration: underline;">KYC Information Request</h1>
          <p> Dear ${formData.name} (Individual) </p>
          <p> ${decodedToken.name} from ${decodedToken.companyName} has requested for you to fill in your details in order to fulfill their KYC/AML obligations.  </p>
          <p> This link is only valid for 48 hours. </p>
          <p> 
            Please use the <a href= '${linkToForm} '> link </a>
            to access the form and fill in the necessary information as requested.
          </p>
          <img src="https://icompass-backend-dev-fhagh5hccpf2ebh9.southeastasia-01.azurewebsites.net/images/from_icompass" alt="iCOMPASS logo" style="display: block; margin: 20px auto; width: 200px; height: auto; max-width: 100%;"> 
          <p style="font-style: italic; margin-top: 20px;">Please do not reply to this email</p>
        </div> `
    })

    const newFormData = {
      ...formData,  
      emailSent : true,
      maxPage : 1
    } 
    setFormData(newFormData)
    await updatekyc(newFormData, token) //catch 
    toast.info(`Form saved, Email notification to ${formData.email} sent!`)
    navigate('/kyc-aml/customer-list')
  }

  const handleSubmitForm = async (e) => { 
    // const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    // setIsValidEmail(emailPattern.test(formData.email));  // Check if the entered email matches the email pattern
    //   for (const key in formData) {
    //         // if (formData[key].trim() === '') {
    //         //     notifyMissing()
    //         //     return 
    //         // }
    // }  

    // 1. check mandatory fields
    
    const requiredFields = ["name", 'address', 'gender', 'identificationNumber']
    let allRequiredFieldsFilled = true
    let newMandatoryFieldError = {...mandatoryFieldError}

    requiredFields.forEach( field => {
      if (!formData[field]) { 
        allRequiredFieldsFilled = false
        newMandatoryFieldError[field] = true 
      } else {
        newMandatoryFieldError[field] = false 
      }
    })
    setMandatoryFieldError(newMandatoryFieldError)

    if (!allRequiredFieldsFilled) {
      toast.info("Please fill in the required fields");
      return;
  }

    // 2. write to DB
    const newFormData = {
      ...formData,  
      formStatus : FormStatusEnum.PENDING_RISK_ASSESSMENT_2,  
      maxPage : 2
    } 
    setFormData(newFormData)
    toast.info("Form Submitted!")
    await updatekyc(newFormData, token)
    // await new Promise(r => setTimeout(r, 200));

    createNotification({
      type: 'KYC',
      text: `${newFormData.name} status has changed to Pending Risk Assessment`,
      date: DateTime.now().toFormat('dd-MM-yyyy HH:mm:ss')
  }, token)

    if (currentUrl !== '/form/kyc-aml') {
      navigate('/kyc-aml/customer-list')
    } else {
      window.location.reload();
    }

  }

  return (
  <div className='max-w-4xl mx-auto'>
    <div className={`max-w-6xl mx-auto mt-3 p-5 rounded-lg shadow-lg bg-white`}>
      <h2 className="text-xl font-semibold mb-1">KYC Form - {individualName} (Individual) </h2>
    </div>

    <div className={`max-w-6xl mx-auto mt-3 p-5 rounded-lg shadow-lg ${readOnly ? 'bg-gray-200' : 'bg-white '}`}>
    {/* 1st row */}
    <div className="grid grid-cols-2 gap-4 mb-4">
        <div>
          <label htmlFor="fullName" className="block font-medium text-gray-700 mb-1">Full Name<i className='text-red-600'>*</i></label>
          <input disabled={readOnly} type="text" id="fullName" name='name' placeholder="Enter Full Name" value={formData.name} onChange={handleInputChange} className="w-11/12 p-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none focus:border-green-500" />
          {mandatoryFieldError.name && <p style={{ color: 'red' }}>Please enter name</p>}
        </div>
        <div>
          <label className="block font-medium text-gray-700 mb-1">Gender<i className='text-red-600'>*</i></label>
          <div className="mt-1 flex items-center">
            <input disabled={readOnly} type="radio" id="male" name="gender" value="male" checked={formData.gender === 'male'} onChange={handleInputChange} className="mr-1 w-4 h-4"/>
            <label htmlFor="male" className="mr-3">Male</label>
            <input disabled={readOnly} type="radio" id="female" name="gender" value="female" checked={formData.gender === 'female'} onChange={handleInputChange} className="mr-1 w-4 h-4"/>
            <label htmlFor="female">Female</label>
            {/* TODO custom radio button  */}
          </div>
          {mandatoryFieldError.gender && <p style={{ color: 'red' }}>Please enter gender</p>}
        </div>
      </div>
      {/* 2nd row */}
      <div className="grid grid-cols-2 gap-4 mb-4">
        <div>
          <label htmlFor="identificationNumber" className="block font-medium text-gray-700 mb-1">Identification Number <i className='text-red-600'>*</i> </label>
          <input disabled={readOnly} type="text" id="identificationNumber" name='identificationNumber' placeholder="Enter Identification Number" value={formData.identificationNumber} onChange={handleInputChange} className="w-11/12 p-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none focus:border-green-500" />
          {mandatoryFieldError.identificationNumber && <p style={{ color: 'red' }}>Please enter Identification Number</p>}
          </div>
        <div>
          <label htmlFor="typeOfIdentification" className="block font-medium text-gray-700 mb-1">Type of Identification</label>
          <select disabled={readOnly} id="typeOfIdentification" value={formData.identificationType} name='identificationType' onChange={handleInputChange} className="w-11/12 p-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none mb-3 focus:border-green-500">
            <option value=""> - Please Select - </option>
            <option value="passport">Passport</option>
            <option value="ic">IC</option>
            <option value="drivingLicense">Driving License</option>
          </select>
        </div>
      </div>
      {/* 3rd row */}
      <div className="grid grid-cols-2 gap-4 mb-4">
        <div>
          <label htmlFor="expiryDate" className="block font-medium text-gray-700 mb-1">Expiry Date of Identification Document (If Applicable)</label>
          <input disabled={readOnly} type="date" value={formData.identificationExpiryDate} name='identificationExpiryDate' onChange={handleInputChange} className="w-11/12 p-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none focus:border-green-500" />
        </div>
        <div>
          <label htmlFor="fileInput" className="block font-medium text-gray-700 mb-1.5">Upload Identification</label>
          {formData.identificationDocumentLink && ( 
            <>
              <a href={`${process.env.REACT_APP_BACKEND_URL}/get-upload?fileName=${formData.identificationDocumentLink.split('/').slice(-1)[0]}&accessToken=${token}`} target='_blank'  rel="noopener noreferrer" className='text-blue-600 mr-2'> {formData.identificationDocumentLink.split('/').slice(-1)[0]} </a> 
              <span className='cursor-pointer' onClick={() => handleFileRemove('identificationDocumentLink', identificationDocumentLinkRef)}> ❌ </span> 
            </>
            )}
          <input disabled={readOnly} ref={identificationDocumentLinkRef} type="file" id="fileInput" name="identificationDocumentLink" onChange={(e) => handleFileUpload(e, 'IdLink')} />
        </div>
      </div>
      {/* 4th row */}
      <div className="grid grid-cols-2 gap-4 mb-4">
        <div>
          <label htmlFor="contactNumber" className="block font-medium text-gray-700 mb-1">Contact Number</label>
          <input disabled={readOnly} type="tel" id="contactNumber" placeholder="Enter Contact Number" name="contactNumber"  value={formData.contactNumber} onChange={handleInputChange} className="w-11/12 p-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none focus:border-green-500" />
        </div>
        <div>
          <label htmlFor="email" className="block font-medium text-gray-700 mb-1">Email</label>
          <input disabled={readOnly} type="email" id="email" placeholder="Enter Email" value={formData.email} onChange={handleInputChange} name="email" className="w-11/12 p-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none focus:border-green-500" />
          {!isValidEmail && <p style={{ color: 'red' }}>Please enter a valid email address</p>}
        </div>
      </div>

      {/* 5th row */}
      <div className="grid grid-cols-2 gap-4 mb-4">
        <div>
          <label htmlFor="dob" className="block font-medium text-gray-700 mb-1">Date of Birth</label>
          <input disabled={readOnly} type="date" id="dob" value={formData.dateOfBirth} onChange={handleInputChange} name="dateOfBirth" className="w-11/12 p-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none focus:border-green-500" />
        </div>
        <div>
          <label className="block font-medium text-gray-700 mb-1">Nationality</label>
          <CountryList  disabled={readOnly}  width={'w-11/12'} value={formData.nationality} handleInputChange={handleInputChange} name='nationality' />
        </div> 
      </div>

      {/* 6th Row */}
      <div className="grid grid-cols-2 gap-4 mb-4">
        <div>
          <label htmlFor="address" className="block font-medium text-gray-700 mb-1">Residence Address<i className='text-red-600'>*</i></label>
          <input disabled={readOnly} type="text" id="address" placeholder="Enter Address" value={formData.address} onChange={handleInputChange} name='address' className="w-11/12 p-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none focus:border-green-500" />
          {mandatoryFieldError.address && <p style={{ color: 'red' }}>Please enter address</p>}
          </div>

        <div>
          <label htmlFor="fileInput" className="block font-medium text-gray-700 my-1.5">Upload Proof of Address </label>
          {formData.addressProofURL && 
            (
            <>
              <a href={`${process.env.REACT_APP_BACKEND_URL}/get-upload?fileName=${formData.addressProofURL.split('/').slice(-1)[0]}&accessToken=${token}`} target='_blank'  rel="noopener noreferrer"  className='text-blue-600 mr-2'> {formData.addressProofURL.split('/').slice(-1)[0]} </a>
              <span className='cursor-pointer' onClick={() => handleFileRemove('addressProofURL', addressProofURLRef)}> ❌ </span> 
            </>
            )}
          <input disabled={readOnly} ref={addressProofURLRef} type="file" id="fileInput" name="addressProofURL" onChange={(e) => handleFileUpload(e, 'addressProofURL')} />
        </div>
      </div>

      {/* 7th row */}
      <div className="grid grid-cols-2 gap-4 mb-4">
        {/* <div>
          <label htmlFor="residenceStatus" className="block font-medium text-gray-700 mb-1">Residence Status</label>
          <select id="residenceStatus" value={formData.residenceStatus} onChange={handleInputChange} name='residenceStatus'className="w-11/12 p-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none mb-3 focus:border-green-500">
            <option value=""> - Please Select - </option>
            <option value="singaporean">Singaporean</option>
            <option value="permanentResident">Permanent Resident</option>
            <option value="foreigner">Foreigner</option>
            <option value="workPermit">Work Permit</option>
          </select>
          {formData.residenceStatus == "workPermit" ? (
            <select value={formData.workPermitType} onChange={handleInputChange} name='workPermitType' className="w-11/12 p-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none mb-3 focus:border-green-500">
              <option value=""> - Please Select - </option>
              <option value="DP">DP</option>
              <option value="EP">EP</option>
              <option value="PEP">PEP</option>
              <option value="entryPass">Entry Pass</option>
            </select>
          ) : ""}
          {formData.residenceStatus == "foreigner" ? (
            <div>
              <label htmlFor="fileInput" className="block font-medium text-gray-700 mb-1.5">Upload Foreigner Proof Of Address</label>
              {formData.foreignerProofOfAddressFile && ( <a href={formData.foreignerProofOfAddressFile} className='text-blue-600 mr-2'> {formData.foreignerProofOfAddressFile.split('/').slice(-1)[0]} </a> )}
              <input disabled={readOnly} type="file" id="fileInput" name="foreignerProofOfAddressFile" onChange={(e) => handleFileUpload(e, 'foreignerProofOfAddressFile')} className='' />
            </div> 
          ) : ""}
        </div> */}
        <div>
          <label  className="block font-medium text-gray-700 mb-1">Country of Residence</label>
          <CountryList disabled={readOnly} width={'w-11/12'} value={formData.residenceCountry} handleInputChange={handleInputChange} name='residenceCountry' />
        </div>
        <div>
          <label htmlFor="occupation" className="block font-medium text-gray-700 mb-1">Occupation</label>
          <input disabled={readOnly} type="text" id="occupation" placeholder="Enter Occupation" onChange={handleInputChange} name='occupation'value={formData?.occupation} className="w-11/12 p-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none focus:border-green-500" />
        </div>
      </div> 

      {/* 8th row */}
      <div className="grid grid-cols-2 gap-4 mb-4">
        {/* <div>
          <label htmlFor="secondNationality" className="block font-medium text-gray-700 mb-1">Second Nationality</label>
          <select id="secondNationality" value={formData.secondNationality} onChange={handleInputChange} name='secondNationality'className="w-11/12 p-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none mb-3 focus:border-green-500">
            <option value="singaporean">Singaporean</option>
            <option value="permanentResident">Permanent Resident</option>
          </select>
        </div> */}
        <div>
          <p className='flex'>
            <label htmlFor="sourceOfWealth" className="block font-medium text-gray-700 mb-1 mr-1">Source of Wealth/Funds</label>
            <Modal title="Definitions" CustomButton={() => 
              <Tooltip 
                title={ <p> Click to View definitions </p> }
                arrow
                componentsProps={{
                  tooltip: {
                    sx: {
                      maxWidth: "600px",
                      fontSize: "14px",
                    },
                  },
                }}
              >
                <span className='text-xl relative bottom-0.5' > ⓘ </span> 
              </Tooltip>
            }>
              <SourceOfWealthFunds />       
            </Modal>
          </p>
          <textarea disabled={readOnly} placeholder="Enter Source of Wealth/Funds" onChange={handleInputChange} name='sourceOfWealth' value={formData.sourceOfWealth} className="w-11/12 p-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none focus:border-green-500" rows={4} />
          {/* <input disabled={readOnly} type="text" id="sourceOfWealth" placeholder="Enter Source of Wealth" onChange={handleInputChange} name='sourceOfWealth' value={formData.sourceOfWealth} className="w-11/12 p-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none focus:border-green-500" /> */}
        </div>
        <div>
          <label htmlFor="Account Manager" className="block font-medium text-gray-700 mb-1">Account Manager</label>
          <input disabled={readOnly} id="Account Manager" placeholder="Enter Account Manager" name="accountManager"  value={formData.accountManager} onChange={handleInputChange} className="w-11/12 p-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none focus:border-green-500" />
        </div>


      </div>

      {/* 9th row */}
      <div className="grid grid-cols-2 gap-4 mb-4">
          <div className={formData.pepPerson === 'yes' ? "border p-2" : "p-2"}>
            <label className="block font-medium text-gray-700">Are you a current or Former PEP?</label>
            <div className="mt-1 flex items-center">
              <input disabled={readOnly} type="radio" id="currentPEP" name="pepPerson" value="yes" onChange={handleInputChange} checked={formData?.pepPerson === 'yes'} className="mr-1 w-4 h-4" />
              <label htmlFor="currentPEP" className="mr-2">Yes</label>
              <input disabled={readOnly} type="radio" id="formerPEP" name="pepPerson" value="no" onChange={handleInputChange} checked={formData?.pepPerson === 'no'} className="mr-1 w-4 h-4"/>
              <label htmlFor="formerPEP">No</label>
            </div>

            {formData.pepPerson === 'yes' && (
                <div className='mt-3 '> 
                    <label className="block font-medium text-gray-700"> Country/Organisation which PEP holds prominent public function</label>
                    <input disabled={readOnly} type="text" name="pepPersonCountryOrOrganisation"  value={formData?.pepPersonCountryOrOrganisation} placeholder="Enter Country/Organisation " onChange={handleInputChange}  className="w-11/12 p-1.5 mb-3 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none focus:border-green-500"/> <br/>    
                    <label className="block font-medium text-gray-700"> Nature of prominent public function </label>
                    <input disabled={readOnly} type="text" name="pepPersonPublicFunctionNature" value={formData?.pepPersonPublicFunctionNature}  placeholder="Enter Nature of prominent public function" onChange={handleInputChange}  className="w-11/12 p-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none mb-3 focus:border-green-500"/> <br/>

                    <label className="font-medium text-gray-700"> From: </label>
                    <input disabled={readOnly} type="date" name="pepPersonRoleFrom" value={formData?.pepPersonRoleFrom} onChange={handleInputChange} id="date" className="w-1/8 p-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none my-1 focus:border-green-500" />
                    <label className="font-medium text-gray-700"> To </label>
                    <input disabled={readOnly} type="date" name="pepPersonRoleTo" value={formData?.pepPersonRoleTo} onChange={handleInputChange} id="date" className="w-1/8 p-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none mb-3 focus:border-green-500" />
                </div>
                )}
          </div>
          
        <div className={formData.pepAssociate === 'yes' ? "border p-2" : "p-2"}>
          <label className="block font-medium text-gray-700 mb-1">Are you a Close Associate of any current or former PEP?</label>
          <div className="mt-1 flex items-center">
            <input disabled={readOnly} type="radio" id="pepYes" name="pepAssociate" value="yes" onChange={handleInputChange} checked={formData.pepAssociate === 'yes'} className="mr-1 w-4 h-4" />
            <label htmlFor="pepYes" className="mr-2">Yes</label>
            <input disabled={readOnly} type="radio" id="pepNo" name="pepAssociate" value="no" onChange={handleInputChange} checked={formData.pepAssociate === 'no'} className="mr-1 w-4 h-4"/>
            <label htmlFor="pepNo">No</label>
          </div>

          {formData.pepAssociate === 'yes' && (
                <div className='mt-3 '> 
                    <p className='font-medium'> Relationship with PEP </p>
                    <input disabled={readOnly} type="text" name="pepAssociateRelationship" value={formData?.pepAssociateRelationship} placeholder="Enter relationship with PEP " onChange={handleInputChange}  className="w-11/12 p-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none mb-3 focus:border-green-500"/>  
                    <p className='font-medium'> PEP Name </p>
                    <input disabled={readOnly} type="text" name="pepAssociateName" value={formData?.pepAssociateName} placeholder="Enter PEP Name" onChange={handleInputChange}  className="w-11/12 p-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none mb-3 focus:border-green-500"/> 
                    <p className='font-medium'> Country/Organisation which PEP holds prominent public function </p> 
                    <input disabled={readOnly} type="text" name="pepAssociateCountryOrOrganisation" value={formData?.pepAssociateCountryOrOrganisation} placeholder="Enter Country/Organisation " onChange={handleInputChange}  className="w-11/12 p-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none mb-3 focus:border-green-500"/>  
                    <p className='font-medium'> Nature of prominent public function </p> 
                    <input disabled={readOnly} type="text" name="pepAssociatePublicFunctionNature" value={formData?.pepAssociatePublicFunctionNature} placeholder="Enter Nature of prominent public function" onChange={handleInputChange}  className="w-11/12 p-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none mb-3 focus:border-green-500"/>  

                    <span className='font-medium'> From: </span>
                    <input disabled={readOnly} type="date" id="date" name="pepAssociateRoleFrom" value={formData?.pepAssociateRoleFrom} onChange={handleInputChange} className="w-1/8 p-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none mb-3 focus:border-green-500" />
                    <span className='font-medium'> To </span>
                    <input disabled={readOnly} type="date" id="date" name="pepAssociateRoleTo" value={formData?.pepAssociateRoleTo} onChange={handleInputChange} className="w-1/8 p-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none mb-3 focus:border-green-500" />
                </div>
                )}

        </div>
      </div>
      {/* 10th row */}
      <div className="grid grid-cols-2 gap-4 mb-4">
          <div className={formData.pepFamily === 'yes' ? "border p-2" : "p-2"}>
            <label className="block font-medium text-gray-700">Are you a family member of any current or former PEP?</label>
            <div className="mt-1 flex items-center">
              <input disabled={readOnly} type="radio" id="familyYes" name="pepFamily" value="yes" onChange={handleInputChange} checked={formData.pepFamily === 'yes'} className="mr-1 w-4 h-4" />
              <label htmlFor="familyYes" className="mr-2">Yes</label>
              <input disabled={readOnly} type="radio" id="familyNo" name="pepFamily" value="no" onChange={handleInputChange} checked={formData.pepFamily === 'no'} className="mr-1 w-4 h-4"/>
              <label htmlFor="familyNo">No</label> 
            </div>

            {formData.pepFamily === 'yes' && (
              <div className='mt-3 '> 
                    <p className='font-medium'> Relationship with PEP  </p> 
                    <input disabled={readOnly} type="text" name="pepFamilyRelationship" value={formData?.pepFamilyRelationship}  placeholder="Enter relationship with PEP " onChange={handleInputChange}  className="w-11/12 p-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none mb-3 focus:border-green-500"/> <br/>  
                    <p className='font-medium'> PEP Name </p> 
                    <input disabled={readOnly} type="text" name="pepFamilyName" value={formData?.pepFamilyName}  placeholder="Enter PEP Name" onChange={handleInputChange}  className="w-11/12 p-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none mb-3 focus:border-green-500"/> <br/>  

                    <p className='font-medium'> Country/Organisation which PEP holds prominent public function  </p> 
                    <input disabled={readOnly} type="text" name="pepFamilyCountryOrOrganisation" value={formData?.pepFamilyCountryOrOrganisation}  placeholder="Enter Country/Organisation " onChange={handleInputChange}  className="w-11/12 p-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none mb-3 focus:border-green-500"/> <br/>  
                    <p className='font-medium'>Nature of prominent public function  </p> 
                    <input disabled={readOnly} type="text" name="pepFamilyPublicFunctionNature" value={formData?.pepFamilyPublicFunctionNature}  placeholder="Enter Nature of prominent public function" onChange={handleInputChange}  className="w-11/12 p-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none mb-3 focus:border-green-500"/> <br/>

                    <span className='font-medium'> From:  </span> 
                    <input disabled={readOnly} type="date" id="date" name="pepFamilyRoleFrom"value={formData?.pepFamilyRoleFrom}   onChange={handleInputChange} className="w-1/8 p-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none mb-3 focus:border-green-500" />
                    <span className='font-medium'> To  </span> 
                    <input disabled={readOnly} type="date" id="date" name="pepFamilyRoleTo" value={formData?.pepFamilyRoleTo}  onChange={handleInputChange} className="w-1/8 p-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none mb-3 focus:border-green-500" />
                </div>
                )}
          </div>

        <div className='hidden'>
                another column
        </div>
      </div>

      <br/><hr/><br/>
      { currentUrl !== '/form/kyc-aml' && !readOnly && (
        <div className='my-4 flex justify-between'>
          <button onClick={handleSaveForm} disabled={readOnly} className='ml-4 bg-green-500 text-white font-semibold py-2 px-4 rounded-md hover:bg-green-600'>
            Save Form
          </button>
 
          <p>
            <button onClick={handleSendEmail} disabled={readOnly} className='ml-4 bg-green-500 text-white font-semibold py-2 px-4 rounded-md hover:bg-green-600'>
              Send Notification Email
            </button> 

            { !formData?.emailSent && (
              <button onClick={handleSubmitForm} disabled={readOnly} className='ml-4 bg-green-500 text-white font-semibold py-2 px-4 rounded-md hover:bg-green-600'>
                Submit
            </button>  
            )}

            { formData?.emailSent && (
              <Tooltip title={<p style={{ fontSize: "15px" }}>Email Notification sent, awaiting customer submission.</p>} arrow className='ml-4 bg-green-300 text-white font-semibold py-2 px-4 rounded-md '>
                <button onClick={handleSubmitForm} disabled={true}  className='ml-4'> Submit </button>  
            </Tooltip> 
            )}

          </p>
        </div>
        )
      }

      {currentUrl === '/form/kyc-aml' && !readOnly && (
        <div className='my-4 flex justify-between'>
          <button onClick={handleSaveForm} className='ml-4 bg-green-500 text-white font-semibold py-2 px-4 rounded-md hover:bg-green-600'> 
            Save Form
          </button>
        <p> 
          <button onClick={handleSubmitForm} className='ml-4 bg-green-500 text-white font-semibold py-2 px-4 rounded-md hover:bg-green-600'> 
            Submit
          </button>
        </p>
      </div>
      )}

    </div>
    <br/><br/> <br/>
</div>
  );
};

export default _IndividualDetails;



const SourceOfWealthFunds = () => {
  return (
    <div className='p-6 w-6xl'>
      <p>
        <strong> Source of wealth: </strong> generally refers to the origin of the customer’s and beneficial owner’s entire body of wealth (i.e. total assets). This relates to how the customer and beneficial owner of the customer have acquired the wealth which is distinct from identifying the assets that they own. <br/> <br/>
        Source of wealth information should give an indication about the size of wealth the customer and beneficial owner would be expected to have, and how the customer and beneficial owner acquired the wealth. Although the CMI may not have specific information about assets that are not deposited with or processed by the CMI, it may be possible to obtain general information from the customer, commercial databases or other open sources. <br/><br/>
        Examples of appropriate and reasonable means of establishing source of wealth are information and documents such as evidence of title, copies of trust deeds, audited accounts, salary details, tax returns and bank statements. <br/><br/>
      </p>
      <hr/> <br/>
      <p>
        <strong> Source of funds: </strong> refers to the origin of the particular funds or other assets which are the subject of the establishment of business relations (e.g. the amounts being invested, deposited, or wired as part of the business relations). <br/><br/>
        In order to ensure that the funds are not proceeds of crime, the CMI should not limit its source of funds inquiry to identifying the other FI from which the funds have been transferred, but more importantly, the activity that generated the funds.  <br/><br/>
        The information obtained should be substantive and facilitate the establishment of the provenance of the funds or reason for the funds having been acquired. Examples of appropriate and reasonable means of establishing source of funds are information such as salary payments or sale proceeds. <br/> 
      </p>
    </div>
  )
}




//  {/* <h4 className="text-lg font-semibold text-center">DECLARATION AS TO TAX COMPLIANCE</h4> <br/>
//       <p className="font-semibold">I, the below named person, being a/the beneficial owner/account signatory of the Entity named below (“the  Entity”) incorporated  in  the  Relevant Jurisdiction  named  below  HEREBY CONFIRM   AND DECLARE AS FOLLOWS: </p> <br/>
//       <ol className="list-decimal ml-6">
//         <li>
//           I am aware of Singapore’s commitment to safeguarding its financial system from being used to harbour orlaunder tax evasion monies or proceeds from serious tax offences with effect from 1 July 2013. Serious tax crimes include omissions, falsifications or fraudulent conduct perpetrated with willful intent to evade tax or to assist others in evading tax
//         </li> <br/>
//         <li>
//         I have sought/shall ensure that the Entity seeks appropriate legal and tax advice and/or fully considers it's legal and tax position with regards to the setting up of the Entity, its holding structure and its tax and other regulatory reporting obligations in all applicable countries;
//         </li> <br/>
//         <li>
//           I have sought appropriate legal and tax advice on and/or am aware and have fully considered my tax and other regulatory reporting obligations in all applicable countries with regards to my interest in the Entity and I undertake to comply with these obligations;
//         </li> <br/>
//         <li>
//           All funds which are to be injected into the Entity whether as loans or equity are sourced from legitimate sources;
//         </li> <br/>
//         <li>
//           Where such funds are required to be reported for tax purposes to anyrelevant revenue authorities, therelevant reporting requirements have been met and unless disputed, alltaxes, if any, which are payable on such funds have been paid when due;
//         </li> <br/>
//         <li>
//           To the best of my knowledge,the Entity and its holding structure are not used in connection with anyserious tax offence, my tax affairs are fully compliant with all applicable laws and regulations and I have not been convicted of any serious tax offence in any country; and
//         </li> <br/>
//         <li>
//           To the best of my knowledge, the Entity and its holding structure are not used in connection with any serious tax offence, my tax affairs are fully compliant with all applicable laws and regulations and I have not been convicted of any serious tax offence in any country; and
//         </li> <br/>
//         <li>
//           I will notify you immediately if there is any change to the circumstances above.
//         </li> <br/> <br/>
//       </ol>

//       <p className="font-semibold">I UNDERSTAND THAT CAMBRIDGE ADVISERS:</p><br/>
//       <ul className="list-disc ml-6">
//         <li>
//           Will rely on the information I have provided above when considering this application for services; and
//         </li> <br/>
//         <li>
//           Is not responsible for providing me and/or the Entity with any legal or tax advice and I confirm that we have not relied on Cambridge Advisers to provide us with such advice.
//         </li> <br/> 
//       </ul>
//       <p className='font-semibold'>DECLARATION:</p> <br/>
//       <ul className="list-disc ml-6">
//         <li>
//           <p> In submitting this form; I declare that all information given in this application and in the attached annexes (if any) is true and correct and that I have not willfully suppressed any material fact.</p> <br/>
//         </li>
//       </ul> */}
//        {/* First Declaration */}
//        {/* <div className="col-span-2 grid grid-cols-2 gap-4">
//         <div>
//           <label htmlFor="declarationName" className="block mb-2">Name:</label>
//           <input disabled={readOnly} type="text" id="declarationName" placeholder="Enter name here" name='declarationName' value={formData?.declarationName} onChange={handleInputChange} className="w-full p-1.5 border-2 border-b-gray-300 bg-gray-100 focus:outline-none mb-3 focus:border-green-500" />
//         </div>
//         <div>
//           <label htmlFor="date" className="block mb-2">Date:</label>
//           <input disabled={readOnly} type="date" id="date" name='declarationDate' onChange={handleInputChange} value={formData?.declarationDate} className="w-full p-1.5 border-2 border-b-gray-300 bg-gray-100 focus:outline-none mb-3 focus:border-green-500" />
//         </div>
//       </div> */}

//         {/* 2nd Declaration */}
//        {/* <div className="col-span-2 grid grid-cols-2 gap-4">
//         <div>
//           <p> Signature: </p> 
          
//           <div className="border border-gray-400 bg-gray-100  mb-3 p-2 "> 
//             <SignatureCanvas
//                 penColor='black'
//                 canvasProps={{ width: 525, height: 200  }}
//                 ref={signatureRef}
//             />
//             <div className='flex justify-between'>
//                 <button onClick={handleClear} className='bg-slate-300 hover:bg-slate-400 text-black font-medium py-1 px-5 rounded-lg'>Clear</button>
//             </div>    
//           </div>
//         </div>
//         <div>
//           <p className="m-0.5"> Saved Signature: </p>
//           <img src={formData?.signatureUrl || ""} className="border h-60 rounded-md m-1 border-gray-600"/>
//         </div>
//       </div> */}